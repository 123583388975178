import React from 'react'
import list from './list.json'
import styled from 'styled-components'
import { Grid } from '../../atoms/Grid'
import { RequirementsListItem } from '../../molecules/RequirementsListItem'
import { Title } from '../../atoms/Title'
import { size } from '../../constants'

export const RequirementsListSection = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <Title.H2asH1styles>Наши ценности</Title.H2asH1styles>
      <ul>
        {list.map(({ title, description }) => (
          <RequirementsListItem
            key={title}
            description={description}
            title={title}
          />
        ))}
      </ul>
    </Grid>
  </section>
))`
  padding-top: 114px;
  padding-bottom: 46px;

  @media (max-width: ${size.lg}) {
    padding-top: 106px;
    padding-bottom: 64px;
  }

  @media (max-width: ${size.md}) {
    padding-top: 88px;
    padding-bottom: 40px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 48px;
    padding-bottom: 0;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 40px;
    padding-bottom: 16px;
  }

  & ${Title.H2asH1styles} {
    margin-bottom: 72px;

    @media (max-width: ${size.md}) {
      margin-bottom: 56px;
    }

    @media (max-width: ${size.sm}) {
      margin-bottom: 48px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 32px;
    }
  }

  & ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
    row-gap: 80px;
    counter-reset: requirements;

    @media (max-width: ${size.lg}) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 40px;
    }

    @media (max-width: ${size.sm}) {
      display: flex;
      flex-direction: column;
      gap: 48px;
    }

    @media (max-width: ${size.xs}) {
      gap: 24px;
    }
  }
`
