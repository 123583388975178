import '../styles/About.scss'
import 'swiper/components/pagination'
import 'swiper/swiper.scss'
import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import OurAwards from '../components/OurAwards'
import React, { useMemo, useRef } from 'react'
import SEO, { getSeoMedia } from '../atoms/SEO'
import Swiper from 'react-id-swiper'
import SwiperCore, { Pagination } from 'swiper'
import WriteUs from '../components/WriteUs'
import { BreadCrumbs, BreadcrumbSEO } from '../molecules/BreadCrumbs'
import { CharitableList } from '../organisms/CharitableList'
import { useOpenPodcast as GAuseOpenPodcast } from '../components/GA'
import { OurMissionSection } from '../organisms/OurMissionSection'
import { RequirementsListSection } from '../organisms/RequirementsListSection'
import { selectDeviceSize } from '../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

SwiperCore.use([Pagination])

const podcast = [
  {
    url: 'https://apple.co/2HF8n3u',
    imgUrl: '/img/about/podcast/180deg.png',
    imgAlt: 'Логотип подкаста 180 градусов',
    srcSet:
      '/img/about/podcast/180deg@2x.png 2x, /img/about/podcast/180deg@3x.png 3x',
    name: '180 градусов'
  },
  {
    url: 'https://pod.link/1500055742/episode/NTg3NTcwYWMtMmQxNC00Mzc2LTgyYWMtNWU0ZWMyMTExYzUx',
    imgUrl: '/img/about/podcast/dark-matter.png',
    imgAlt: 'Логотип подкаста Темная материя',
    srcSet:
      '/img/about/podcast/dark-matter@2x.png 2x, /img/about/podcast/dark-matter@3x.png 3x',
    name: 'Тёмная материя'
  },
  {
    url: 'https://music.yandex.ru/album/10442961/track/65475714',
    imgUrl: '/img/about/podcast/businesswomen.png',
    imgAlt: 'Логотип подкаста Предпринимательницы',
    srcSet:
      '/img/about/podcast/businesswomen@2x.png 2x, /img/about/podcast/businesswomen@3x.png 3x',
    name: 'Предприни-мательницы'
  },
  {
    url: 'https://www.lenadegtyar.com/you-talk/',
    imgUrl: '/img/about/podcast/lenapodcast.png',
    imgAlt: 'Логотип подкаста Лена Дегтярь',
    srcSet:
      '/img/about/podcast/lenapodcast@2x.png 2x, /img/about/podcast/lenapodcast@3x.png 3x',
    name: 'Лена Дегтярь'
  },
  {
    url: 'https://music.yandex.ru/album/6325259/track/71702295',
    imgUrl: '/img/about/podcast/ok.png',
    imgAlt: 'Логотип подкаста НОРМ',
    srcSet: '/img/about/podcast/ok@2x.png 2x, /img/about/podcast/ok@3x.png 3x',
    name: 'НОРМ'
  }
]

const orientedCard = [
  {
    img: '/img/icons/about/1.svg',
    imgAlt:
      'Женщина с маленьким мальчиком и белым домашним животным, похожем на фенька',
    name: 'Мамы в декрете'
  },
  {
    img: '/img/icons/about/2.svg',
    imgAlt: 'Мужчина в инвалидном кресле-коляске',
    name: 'Люди с ограниченными возможностями здоровья'
  },
  {
    img: '/img/icons/about/4.svg',
    imgAlt:
      'Женщина с шарфиком смотрит на символичное изображение Эйфелевой башни',
    name: 'Русскоязычные мигранты'
  },
  {
    img: '/img/icons/about/5.svg',
    imgAlt:
      'На переднем плане девушка, за ее спиной две устрашающие бесформенные тени синего цвета',
    name: 'Люди с социофобией\n и агорафобией'
  },
  {
    img: '/img/icons/about/6.svg',
    imgAlt: 'Девушка закрывает глаза руками',
    name: 'Жертвы насилия'
  }
]

const Podcast = ({
  url,
  name,
  imgUrl,
  imgAlt,
  srcSet,
  className,
  ...props
}) => (
  <a
    className={`podcasts-slider__link podcasts-slider-item ${className}`}
    href={url}
    onClick={GAuseOpenPodcast(name)}
    rel="noreferrer"
    target="_blank"
    {...props}
  >
    <img
      alt={imgAlt}
      className="podcasts-slider-item__img"
      src={imgUrl}
      srcSet={srcSet}
    />
    <span className="podcasts-slider-item__name">{name}</span>
  </a>
)

const About = () => {
  const wWidth = useSelector(selectDeviceSize)
  const paginationRefSocial = useRef(null)

  const params = {
    loop: false,
    grabCursor: true,
    slidesPerView: 'auto',
    spaceBetween: 35,
    preventClicks: false,
    preventClicksPropagation: false,
    breakpoints: {
      1439: {
        spaceBetween: 70
      }
    }
  }

  const paramsBusinessOriented = {
    loop: false,
    grabCursor: true,
    pagination: {
      clickable: true,
      type: 'bullets',
      el: paginationRefSocial.current
    },
    slidesPerView: 1,
    spaceBetween: 0,
    preventClicks: false,
    preventClicksPropagation: false,
    on: {
      beforeInit: ({ params }) => {
        params.pagination.el = paginationRefSocial.current
      }
    }
  }

  return (
    <>
      <App>
        <SEO
          children={<BreadcrumbSEO />}
          description="Онлайн-сервис психологической помощи YouTalk. Более 280 психологов в команде и 22 тысячи человек, обратившихся за консультацией."
          images={getSeoMedia([
            { pathname: '/img/og/about.png', alt: 'about' }
          ])}
          title="О нас"
        />
        <Header />
        <BreadCrumbs />
        <main className="main">
          <section className="about-prev">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12">
                  <h1 className="about-prev__title">YouTalk</h1>
                  <p className="about-prev__desc">
                    Это онлайн-сервис психологической помощи, созданный командой
                    выпускников факультета психологии МГУ им. М.В. Ломоносова.
                    Мы помогаем людям найти своего специалиста и&nbsp;получить
                    профессиональную поддержку в&nbsp;удобном и&nbsp;современном
                    формате.
                  </p>
                  <img
                    alt="На рабочем столе стоят цветок, кружка, раскрытая тетрадь и ноутбук.
                      На экране ноутбука две девушки, общающиеся по видеосвязи"
                    className="about-prev__img"
                    src="/img/icons/about/prev.svg"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="founders">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12">
                  <div className="founders__head">
                    <h2 className="founders__title h1">Основатели YouTalk</h2>
                    <p className="founders__desc">
                      Клинические психологи и практикующие терапевты в прошлом
                    </p>
                  </div>
                  <div className="founders__wrap">
                    <div className="founders__col founders-col">
                      <div className="founders-col__card founders-card">
                        <img
                          alt="Фотография соосновательницы сервиса YouTalk Анны Крымской"
                          className="founders-card__ava"
                          src="/img/about/Anna.png"
                          srcSet="/img/about/Anna@2x.png 2x, /img/about/Anna@3x.png 3x"
                        />
                        <h5 className="founders-card__name h5">
                          Анна Крымская
                        </h5>
                      </div>
                    </div>
                    <div className="founders__col founders-col">
                      <blockquote className="founders-col__slogan">
                        Мы знаем, что нуждаться в&nbsp;помощи и&nbsp;поддержке
                        в&nbsp;трудные периоды жизни абсолютно нормально для
                        любого человека, и&nbsp;стремимся сделать психотерапию
                        безопасной, удобной и&nbsp;доступной каждому.
                      </blockquote>
                    </div>
                    <div className="founders__col founders-col">
                      <div className="founders-col__card founders-card">
                        <img
                          alt="Фотография соосновательницы сервиса YouTalk Александры Темляковой"
                          className="founders-card__ava"
                          src="/img/about/Alexandra.png"
                          srcSet="/img/about/Alexandra@2x.png 2x, /img/about/Alexandra@3x.png 3x"
                        />
                        <h5 className="founders-card__name h5">
                          Александра Темлякова
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              alt="Светло-зеленая фигура"
              className="founders__decor"
              src="/img/icons/about/founders.svg"
            />
          </section>

          <section className="podcasts">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12">
                  <h2 className="podcasts__title h1">Подкасты про YouTalk</h2>
                  <p className="podcasts__desc">
                    Здесь собраны наши интервью, комментарии и рассказы про
                    YouTalk. Переходите по ссылкам и слушайте.
                  </p>
                  <Swiper {...params} containerClass="podcasts-slider">
                    {podcast.map(({ name, ...props }) => (
                      <Podcast key={name} name={name} {...props} />
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </section>
          <OurMissionSection />
          <RequirementsListSection />

          <section className="our-psychologists">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12">
                  <div className="our-psychologists__wrap">
                    <div className="our-psychologists__img-wrap">
                      <img
                        alt="Женщина с книгой на фоне висящих на стене дипломов и сертификатов"
                        src="/img/icons/about/psychologists.svg"
                      />
                    </div>
                    <div className="our-psychologists__content">
                      <h2 className="our-psychologists__title h1">
                        Наши психологи
                      </h2>
                      <p className="our-psychologists__desc">
                        Это профессиональное комьюнити с&nbsp;уникальным опытом
                        работы в&nbsp;формате онлайн&#8209;консультирования. Мы
                        тщательно отбираем и&nbsp;обучаем каждого специалиста
                        и&nbsp;вкладываем много ресурсов в&nbsp;развитие
                        команды.
                        <br />
                        <br /> Все наши специалисты имеют высшее психологическое
                        образование и&nbsp;дополнительно обучались психотерапии.
                        <br />
                        <br />У каждого за плечами стаж консультирования от трёх
                        лет, опыт работы с&nbsp;супервизором и&nbsp;прохождение
                        личной терапии.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="business-oriented">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12">
                  <h2 className="business-oriented__title h1">
                    YouTalk — это социально ориентированный бизнес
                  </h2>
                  <p className="business-oriented__desc">
                    Мы помогаем получить психологическую помощь людям, которые
                    иначе сейчас её получить просто не могут.
                  </p>
                  <>
                    {wWidth > 767 ? (
                      <div className="business-oriented__wrap">
                        {orientedCard.map(({ img, imgAlt, name }, i) => (
                          <div
                            key={`business-oriented${i}`}
                            className="business-oriented__card business-oriented-card"
                          >
                            <img
                              alt={imgAlt}
                              className="business-oriented-card__img"
                              src={img}
                            />
                            <p className="business-oriented-card__name">
                              {name}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <Swiper
                          {...paramsBusinessOriented}
                          containerClass="our-team__slider our-team-slider"
                        >
                          {orientedCard.map(({ img, name }, i) => (
                            <div
                              key={`business-oriented${i}`}
                              className="business-oriented__card business-oriented-card"
                            >
                              <img
                                alt={name}
                                className="business-oriented-card__img"
                                src={img}
                              />
                              <p className="business-oriented-card__name">
                                {name}
                              </p>
                            </div>
                          ))}
                        </Swiper>
                        <div
                          ref={paginationRefSocial}
                          className="our-team__dots sl-dots"
                        ></div>
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
            {wWidth > 1023 && (
              <>
                <img
                  alt="Светло-красный полукруг"
                  className="business-oriented__red-blob"
                  src="/img/icons/about/red-blob.svg"
                />
                <img
                  alt="Светло-синий полукруг"
                  className="business-oriented__blue-blob"
                  src="/img/icons/about/blue-blob.svg"
                />
              </>
            )}
          </section>

          <section className="charitable">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12">
                  <h2 className="charitable__title">
                    А также участвуем
                    <br />в благотворительных проектах
                  </h2>
                  <div className="charitable__list-wrap">
                    <ul className="charitable-list">
                      <CharitableList
                        swiper={useMemo(() => wWidth < 768, [wWidth])}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <img
              alt="Светло-зеленая фигура"
              className="charitable__decor"
              src="/img/icons/about/charitable.svg"
            />
          </section>

          <OurAwards />

          <WriteUs page="about" />
        </main>

        <Footer />
      </App>
    </>
  )
}

export default About
