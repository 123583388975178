import React from 'react'
import styled from 'styled-components'
import { Montserrat, size } from '../../constants'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { color } from '../../styles/vars/colors'

export const RequirementsListItem = styled(
  ({ className, title, description }) => (
    <li className={className}>
      <Title.H3>{title}</Title.H3>
      <Text.Medium>{description}</Text.Medium>
    </li>
  )
)`
  position: relative;
  padding-top: 66px;

  &::before {
    content: counter(requirements);
    counter-increment: requirements;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    font-family: ${Montserrat};
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    border: 2px solid #16c8bb;
    border-radius: 50%;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 0;
    padding-left: 58px;
  }

  & > ${Title.H3} {
    margin-bottom: 16px;

    @media (max-width: ${size.sm}) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (max-width: ${size.xs}) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  & > ${Text.Medium} {
    color: ${color.text.dark};
  }
`
