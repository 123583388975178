import React from 'react'
import styled from 'styled-components'
import videoCall from './img/video-call.svg'
import { Grid } from '../../atoms/Grid'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { size } from '../../constants'

const BrLgOnly = styled.br`
  @media (max-width: ${size.md}) {
    display: none;
  }
`

const BrSmOnly = styled.br`
  display: none;

  @media (max-width: ${size.md}) {
    display: inline-block;
  }

  @media (max-width: ${size.sm}) {
    display: none;
  }
`

export const OurMissionSection = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <Title.H2asH1styles>Наша миссия</Title.H2asH1styles>
      <Text.Medium>
        Мы помогаем людям раскрыть их
        <BrSmOnly /> внутреннюю
        <BrLgOnly /> силу, чтобы справляться
        <BrSmOnly /> с трудностями, строить
        <BrLgOnly /> счастливые
        <BrSmOnly /> отношения и жить <br />в гармонии с собой
      </Text.Medium>
      <img alt="Видеозвонок двух девушек" src={videoCall} />
    </Grid>
  </section>
))`
  position: relative;
  padding-top: 72px;
  padding-bottom: 89px;
  margin-top: 85px;

  @media (max-width: ${size.lg}) {
    margin-top: 45px;
  }

  @media (max-width: ${size.md}) {
    padding-top: 48px;
    padding-bottom: 56px;
    margin-top: 81px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 32px;
    padding-bottom: 0;
    margin-top: 56px;
  }

  @media (max-width: ${size.xs}) {
    margin-top: 93px;
  }

  &::before {
    content: '';
    position: absolute;
    background: #eff5fb;
    bottom: 0;
    left: 0;
    height: calc(100% - 32px);
    width: 100vw;

    @media (max-width: ${size.sm}) {
      height: 238px;
      bottom: unset;
      top: 32px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    border-radius: 100% 100% 0 0 / 50px 50px 0 0;
    background: #eff5fb;
    width: 100%;
    height: 34px;
    top: 0;

    @media (max-width: ${size.md}) {
      border-radius: 100% 100% 0 0 / 24px 24px 0 0;
    }

    @media (max-width: ${size.sm}) {
      border-radius: 30% 30% 0 0 / 8px 8px 0 0;
    }
  }

  & ${Title.H2asH1styles} {
    margin-bottom: 24px;

    @media (max-width: ${size.md}) {
      margin-bottom: 16px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 24px;
    }
  }

  & ${Text.Medium} {
    @media (max-width: ${size.md}) {
      max-width: 356px;
    }

    @media (max-width: ${size.sm}) {
      max-width: unset;

      & br {
        display: none;
      }
    }
  }

  & img {
    position: absolute;
    right: 110px;
    top: 0;

    @media (max-width: ${size.lg}) {
      width: 381px;
    }

    @media (max-width: ${size.md}) {
      width: 294px;
      right: 46px;
    }

    @media (max-width: ${size.sm}) {
      position: relative;
      width: 240px;
      right: unset;
      display: block;
      margin: auto;
      margin-top: 24px;
    }
  }
`
