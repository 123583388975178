import React from 'react'
import Swiper from 'react-id-swiper'
import { useOpenProject as GAuseOpenProject } from '../components/GA'

const paramsBenevolentProject = {
  loop: false,
  grabCursor: true,
  slidesPerView: 'auto',
  spaceBetween: 0,
  preventClicks: false,
  preventClicksPropagation: false
}

const charitableList = [
  {
    gaLabel: 'age',
    href: 'https://www.asi.org.ru/news/2020/06/10/yandeksoplachi/',
    src: '/img/about/charitable/1.png',
    imgAlt:
      'Рисунок улыбающихся и обнимающихся старых мужчины и женщины на природе с логотипом фонда «Старость в радость»',
    srcSet:
      '/img/about/charitable/1@2x.png 2x, /img/about/charitable/1@3x.png 3x',
    Description: () => (
      <>
        Бесплатная психологическая поддержка одиноких пожилых людей вместе
        с&nbsp;фондом «Старость в радость» и&nbsp;проектом Яндекса «Помощь
        рядом».
      </>
    )
  },
  {
    gaLabel: 'belarus',
    href: 'https://daily.afisha.ru/news/40396-youtalk-vydelil-besplatnye-onlayn-konsultacii-dlya-postradavshih-v-belarusi/',
    src: '/img/about/charitable/3.png',
    imgAlt: 'Фотография мальчика, закрывающего глаза руками',
    srcSet:
      '/img/about/charitable/3@2x.png 2x, /img/about/charitable/3@3x.png 3x',
    Description: () => (
      <>
        Бесплатные консультации для жителей Беларуси, пострадавших
        от&nbsp;политического кризиса в&nbsp;2020 году.
      </>
    )
  },
  {
    gaLabel: 'impactHub',
    href: 'https://rb.ru/news/programma-nachni-inache/',
    src: '/img/about/charitable/2.png',
    imgAlt:
      'Фотография мужчины, выступающего с презентацией перед сидящими людьми',
    srcSet:
      '/img/about/charitable/2@2x.png 2x, /img/about/charitable/2@3x.png 3x',
    Description: () => (
      <>
        Психологическая поддержка социальных предпринимателей, участвующих
        в&nbsp;программах Impact Hub Moscow.
      </>
    )
  }
]

const CharitableItem = ({
  href,
  src,
  imgAlt,
  srcSet,
  Description,
  gaLabel
}) => {
  const onOpen = GAuseOpenProject(gaLabel)
  return (
    <>
      <a
        className="charity-project__link"
        href={href}
        rel="noreferrer"
        target="_blank"
      >
        <img
          alt={imgAlt}
          className="charity-project__prev"
          onClick={onOpen}
          src={src}
          srcSet={srcSet}
        />
      </a>
      <p className="charity-project__desc">
        <Description />
      </p>
    </>
  )
}

export const CharitableList = ({ swiper }) =>
  swiper ? (
    <Swiper
      {...paramsBenevolentProject}
      containerClass="charitable-list__item charity-project"
    >
      {charitableList.map(
        ({ gaLabel, href, src, imgAlt, srcSet, Description }) => (
          <li key={gaLabel} className="charitable-list__item charity-project">
            <CharitableItem
              key={gaLabel}
              Description={Description}
              gaLabel={gaLabel}
              href={href}
              src={src}
              imgAlt={imgAlt}
              srcSet={srcSet}
            />
          </li>
        )
      )}
    </Swiper>
  ) : (
    charitableList.map(({ gaLabel, href, src, srcSet, Description }) => (
      <li key={gaLabel} className="charitable-list__item charity-project">
        <CharitableItem
          key={gaLabel}
          Description={Description}
          gaLabel={gaLabel}
          href={href}
          src={src}
          srcSet={srcSet}
        />
      </li>
    ))
  )
