import './index.scss'
import React, { useEffect, useState } from 'react'
import { useOpenAward as GAuseOpenAward } from '../GA'
import { awards } from '../../../static/db.json'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

const Award = ({ googleAnalytics, href, img, alt, desc }) => (
  <li className="our-awards-list__item awards-item">
    <a
      className="awards-item__link"
      href={href}
      onClick={GAuseOpenAward(googleAnalytics)}
      rel="noreferrer"
      target="_blank"
    >
      <img alt={alt} className="awards-item__img" src={img} />
      <p className="awards-item__desc">{desc}</p>
    </a>
  </li>
)

const OurAwards = () => {
  const [stateAwards, setStateAwards] = useState()
  const windowWidth = useSelector(selectDeviceSize)

  useEffect(() => {
    if (windowWidth < 1024 && windowWidth > 768) {
      setStateAwards([awards[0], awards[2], awards[1], awards[3]])
      return
    }
    setStateAwards(awards)
  }, [windowWidth])

  return (
    <section className="our-awards">
      <div className="container">
        <div className="row">
          <div className="designer-col col-12">
            <h2 className="our-awards__title h1">Наши премии</h2>
            <ul className="our-awards-list">
              {stateAwards &&
                stateAwards.map((award) => (
                  <Award {...award} key={award.href} />
                ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurAwards
